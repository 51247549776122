
export const BoomerangRoutes = {
  BOOMERANG_PROFILE_REVIEW: '/boomerang/profile-review',
  BOOMERANG_PROFILE_REVIEW_EDIT_ADDRESS: '/boomerang/profile-review/edit-address',
  BOOMERANG_PROFILE_REVIEW_EDIT_DRIVER: '/boomerang/profile-review/edit-driver/:universalDriverId',
  BOOMERANG_PROFILE_REVIEW_EDIT_VEHICLE: '/boomerang/profile-review/edit-vehicle/:cid',
  BOOMERANG_STUCK: '/boomerang/stuck',
  BOOMERANG_PROFILE_REVIEW_DRIVER_VEHICLE_ASSIGNMENTS: '/boomerang/profile-review/driver-vehicle-assignment/driver-vehicle',
  BOOMERANG_PROFILE_REVIEW_VEHICLE_DRIVER_ASSIGNMENTS: '/boomerang/profile-review/driver-vehicle-assignment/vehicle-driver',
  BOOMERANG_PROFILE_REVIEW_RIDESHARE: '/boomerang/profile-review/rideshare',
  BOOMERANG_REPAYMENT_EXPLAINER: '/boomerang/repayment-explainer',
};

export const Routes = {
  ACCOUNT_SETTINGS: '/account-settings',
  DISCLAIMERS: '/disclaimers',
  DOCUMENTS: '/documents',
  HELP: '/help',
  OVERVIEW: '/overview',
  PAYMENTS: '/payments',
  POLICY: '/policy',
  QUOTES: '/quotes',
  SPLASH: '/splash',
  ...BoomerangRoutes,
};
