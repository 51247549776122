import AbTestDispatchContext from '@root/core/src/contexts/ab-test/ab-test-dispatch-context';
import PropTypes from '@root/vendor/prop-types';
import React, { lazy, useContext } from '@root/vendor/react';
import SplashEntry from '@root/splash/src/splash-entry';
import environment from '@root/core/src/utils/environment';
import { Redirect, Route, Switch } from '@root/core/src/components/route';
import { Routes } from '@root/account/src/routes';
import { useHistory } from '@root/vendor/react-router-dom';

const AccountAutoPolicy = lazy(() => import(/* webpackChunkName: "account-auto-policy" */ '@root/account.joinroot.com/src/scenes/account-auto-policy'));
const AccountSettings = lazy(() => import(/* webpackChunkName: "account-settings" */ '@root/account.joinroot.com/src/scenes/account-settings'));
const BoomerangEntry = lazy(() => import(/* webpackChunkName: "boomerang-entry" */ '@root/boomerang/src/boomerang-entry'));
const ContactSupportEntry = lazy(() => import(/* webpackChunkName: "contact-support" */ '@root/contact-support/src/contact-support-entry'));
const Documents = lazy(() => import(/* webpackChunkName: "documents" */ '@root/account.joinroot.com/src/scenes/documents'));
const Disclaimers = lazy(() => import(/* webpackChunkName: "disclaimers" */ '@root/account.joinroot.com/src/scenes/disclaimers'));
const Overview = lazy(() => import(/* webpackChunkName: "overview" */ '@root/account.joinroot.com/src/scenes/overview'));
const Payments = lazy(() => import(/* webpackChunkName: "payments" */ '@root/account.joinroot.com/src/scenes/payments'));
const QuotesEntry = lazy(() => import(/* webpackChunkName: "quotes" */ '@root/quotes/src/quotes-entry'));
const TriageEntry = lazy(() => import(/* webpackChunkName: "triage" */ '@root/triage/src/triage-entry'));
const UploadSessionEntry = lazy(() => import(/* webpackChunkName: "upload-session" */ '@root/upload-session/src/upload-session-entry'));

const EntryPrefixes = {
  BOOMERANG: '/boomerang',
  CONTACT_SUPPORT: '/contact-support',
  QUOTES: '/quotes',
  TRIAGE: '/triage',
  UPLOAD_SESSION: '/upload-session',
};

export default function ProtectedRouter({ passProps }) {
  const initialRoute = Routes.OVERVIEW;
  const abTestDispatch = useContext(AbTestDispatchContext);
  const history = useHistory();

  return (
    <Switch>
      <Route
        exact={true}
        path={'/'}
        render={() => <Redirect to={initialRoute} />}
      />
      <Route
        path={Routes.OVERVIEW}
        render={() => <Overview {...passProps} />}
      />
      <Route
        path={Routes.POLICY}
        render={() => <AccountAutoPolicy {...passProps} />}
      />
      <Route
        path={Routes.DOCUMENTS}
        render={() => <Documents {...passProps} />}
      />
      <Route
        path={Routes.DISCLAIMERS}
        render={() => <Disclaimers {...passProps} />}
      />
      <Route
        path={Routes.PAYMENTS}
        render={() => <Payments {...passProps} />}
      />
      <Route
        path={Routes.ACCOUNT_SETTINGS}
        render={() => <AccountSettings {...passProps} />}
      />
      <Route
        path={Routes.SPLASH}
        render={() => <SplashEntry passProps={{}} />}
      />
      <Route
        path={EntryPrefixes.BOOMERANG}
        render={() => <BoomerangEntry {...passProps} />}
      />
      <Route
        path={EntryPrefixes.TRIAGE}
        render={() => <TriageEntry />}
      />
      <Route
        path={EntryPrefixes.QUOTES}
        render={() => (
          <QuotesEntry
            abTestDispatch={abTestDispatch}
            isBoomerang={true}
            onFlowComplete={() => history.push('/triage/success-download-app/boomerang')}
            onLogout={passProps.onLogout}
          />
        )}
      />
      <Route
        path={EntryPrefixes.CONTACT_SUPPORT}
        render={() => <ContactSupportEntry passProps={{}} />}
      />
      {!!environment.isUploadSessionFlowEnabled &&
        <Route
          path={EntryPrefixes.UPLOAD_SESSION}
          render={() => <UploadSessionEntry />}
        />
      }
      <Redirect to={'/'} />
    </Switch>
  );
}

export { Routes };

ProtectedRouter.propTypes = {
  passProps: PropTypes.object.isRequired,
};
